
























/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/camelcase */

import { Component, Vue } from 'vue-property-decorator'
import '@stripe/stripe-js'
import axios from 'axios'

const stripe = window.Stripe!('pk_test_avg2lBvVlNEDUT3tI7F70u0U00XjHAri6w')
const elements = stripe.elements()
let card: any

@Component({ })
export default class TestProd extends Vue {
  public isActive = true;
  private releaseId = '607c1188fc1e041394c1e46a' // from api *soon*
  public checkoutProcess = false;

  async mounted (): Promise<void> {
    /*
    if (this.$route.query?.password) {
      const res = await this.
    }
    */
    const style = {
      base: {
        'iconColor': '#666ee8',
        'color': '#31325f',
        'fontWeight': 400,
        'fontFamily':
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '15px',
        'fontColor': '#aab7c4',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#666ee8'
        }
      }
    }

    card = elements.create('card', { style })
    card.mount('#card')

    card.on('change', (error: any) => {
      console.log('change!!!')
      this.displayError(error)
    })
  }

  public async purchase (): Promise<void> {
    const email = (document.getElementById('email') as HTMLInputElement).value

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: { email }
      })

      if (error || !paymentMethod) throw error
      else {
        const checkout = await this.createSubscription({
          paymentMethodId: paymentMethod.id,
          releaseId: this.releaseId,
          email
        })
        console.log('checkout: ', checkout)
      }
    } catch (err) {
      this.displayError(err)
      console.error('Error:', err)
    }
  }

  public async createSubscription ({ paymentMethodId, releaseId, email }: { paymentMethodId: string; releaseId: string; email: string }): Promise<any> {
    try {
      const { data } = await axios.post('http://localhost:4200/checkout', {
        paymentMethodId,
        releaseId,
        email
      }, { withCredentials: true })

      this.displayEvent(data.status)

      if (data.status === 'succeeded') {
        return {
          paymentMethodId,
          releaseId,
          subscription: data
        }
      } else if (data.status === 'requires_action' && data.client_secret) {
        await this.handlePaymentThatRequiresCustomerAction(data.client_secret, paymentMethodId)
      }
    } catch (error) {
      if (error.response) {
        this.displayError(error.response.data)
      } else {
        this.displayError(error)
      }
      console.error('Error occured @ creating Subscription:', error)
    }
  }

  // https://stripe.com/docs/js/payment_intents/confirm_card_payment
  private async handlePaymentThatRequiresCustomerAction (
    client_secret: string,
    paymentMethod: string
  ) {
    try {
      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: paymentMethod
      })

      if (result.error) {
        // Start code flow to handle updating the payment details.
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc)
        throw result.error
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer.
          this.displayEvent('PAYMENT SUCCEEDED AFTER 3DS')
          return { result }
        }
      }
    } catch (error) {
      this.displayError(error)
      return error
    }
  }
  /*
  private async checkPassword (password: string) {
    try {
      const { data } = await axios({
        method: 'get',
        url: `http://localhost:4200/releases/pw/${password}`,
        withCredentials: true
      })
      console.log('datatatata: ', data)
    } catch (error) {
      return false
    }
  }
  */

  private displayError (error: any) {
    const cardErrors = document.getElementById('card-errors')!
    if (error) {
      console.log('with error', error)
      cardErrors.textContent = error.message
      if (error.error || error.complete === false) this.isActive = false
      else this.isActive = true
    } else {
      console.log('without error')
      cardErrors.textContent = ''
      this.isActive = true
    }
    // Re-enable the Pay button.
    // this.isActive = true
  }

  private displayEvent (event: any) {
    const cardEvent = document.getElementById('card-events')!
    console.log('EVENT:', event)
    cardEvent.textContent = event
  }
}
